import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from './utils/theme';
import { FirebaseAuthProvider } from './actions/FirebaseAuthContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<ThemeProvider theme={theme}>
		<CssBaseline />
		<FirebaseAuthProvider>
			<App />
		</FirebaseAuthProvider>
	</ThemeProvider>
);
