import React, { useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

const AddSection = ({ open, handleClose, handleCreate }) => {
	const [title, setTitle] = useState('');
	const handleSubmit = (e) => {
		e.preventDefault();

		handleCreate({ title, section_id: uuidv4() });
		setTitle('');
		handleClose();
	};

	return (
		<Dialog
			open={open}
			onClose={() => {
				handleClose();
				setTitle('');
			}}
			fullWidth
			maxWidth="sm"
		>
			<DialogTitle>Add Section</DialogTitle>
			<form onSubmit={handleSubmit}>
				<DialogContent>
					<TextField
						required
						autoFocus
						margin="dense"
						label="Section Title"
						fullWidth
						variant="standard"
						name="title"
						value={title}
						onChange={(e) => setTitle(e.target.value)}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							handleClose();
							setTitle('');
						}}
					>
						Cancel
					</Button>
					<Button type="submit">Save</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default AddSection;
