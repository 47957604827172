import React, { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../utils/firebase';
import CircularProgress from '@mui/material/CircularProgress';

const FirebaseAuthContext = createContext({});

export const FirebaseAuthProvider = ({ children }) => {
	const [currentUser, setCurrentUser] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, (user) => {
			user ? setCurrentUser(user) : setCurrentUser(null);
			setLoading(false);
		});

		return () => unsubscribe();
	}, []);

	if (loading) {
		return (
			<div
				style={{
					width: '100%',
					height: '100vh',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<CircularProgress />
			</div>
		);
	}

	return (
		<FirebaseAuthContext.Provider value={{ currentUser, setCurrentUser }}>
			{children}
		</FirebaseAuthContext.Provider>
	);
};

export const useFirebaseAuth = () => useContext(FirebaseAuthContext);
