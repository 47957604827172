import React, { useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	IconButton,
	Stack,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import StatusDropdown from './StatusDropdown';
import leadControllers from '../../controllers/lead.controllers';

const list = ['open', 'opened', 'rejected', 'contacted'];

const MessageModal = ({ open, handleClose, data }) => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
	const [currStatus, setCurrentStatus] = useState(data?.status);

	const handleSelect = (value) => {
		setCurrentStatus(value);
	};

	const handleStatusChange = async () => {
		const [_, errForUpdatedLead] = await leadControllers.updateLeadStatus(
			data?.id,
			currStatus
		);
		if (errForUpdatedLead) return [null, errForUpdatedLead];

		handleClose();
	};

	if (!data) return null;

	return (
		<Dialog
			onClose={handleClose}
			aria-labelledby="customized-dialog-title"
			open={open}
			scroll="paper"
			fullScreen={fullScreen}
		>
			<Stack
				direction="row"
				spacing={2}
				justifyContent="space-between"
				alignItems="center"
			>
				<DialogTitle sx={{ flexGrow: 1 }} id="customized-dialog-title">
					{data?.full_name}
				</DialogTitle>

				<StatusDropdown
					selected={currStatus}
					handleSelect={handleSelect}
					list={list}
				/>

				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={{
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			</Stack>
			<DialogContent dividers>
				<Stack
					direction="row"
					spacing={1}
					justifyContent="space-between"
					alignItems="center"
				>
					<Stack
						direction="row"
						spacing={2}
						justifyContent="space-between"
						alignItems="center"
					>
						<TextField
							label="Email"
							defaultValue={data?.email}
							InputProps={{ readOnly: true }}
							variant="standard"
							size="small"
						/>
						<IconButton
							aria-label="copy"
							color="primary"
							size="small"
							onClick={() => navigator.clipboard.writeText(data?.email)}
						>
							<ContentCopyOutlinedIcon />
						</IconButton>
					</Stack>
					<Stack
						direction="row"
						spacing={2}
						justifyContent="space-between"
						alignItems="center"
					>
						<TextField
							label="Phone Number"
							defaultValue={data?.phone_number}
							InputProps={{ readOnly: true }}
							variant="standard"
							size="small"
						/>
						<IconButton
							aria-label="copy"
							color="primary"
							size="small"
							onClick={() => navigator.clipboard.writeText(data?.phone_number)}
						>
							<ContentCopyOutlinedIcon />
						</IconButton>
					</Stack>
				</Stack>
				<Divider sx={{ my: 2 }} />
				<Typography gutterBottom>{data?.message}</Typography>
			</DialogContent>
			<DialogActions>
				<Typography variant="body2" color="secondary" sx={{ mr: 2 }}>
					{new Date(data?.created_at?.nanoseconds).toLocaleString()}
				</Typography>
				<Button autoFocus onClick={handleStatusChange}>
					Save changes
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default MessageModal;
