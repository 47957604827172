import React from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	FormControl,
	OutlinedInput,
	Stack,
} from '@mui/material';

const UpdateSection = ({ open, handleClose, data, setData, handleUpdate }) => {
	const handleSectionInputChange = (e) => {
		const { name, value } = e.target;
		setData({ ...data, [name]: value });
	};

	const handleSubSectionInputChange = (e) => {
		const { name, value } = e.target;
		const items = data?.items?.map((item) => {
			if (item?.subsection_id === name) {
				return { ...item, title: value };
			} else {
				return item;
			}
		});
		setData({ ...data, items });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		handleUpdate(data);
		handleClose();
	};

	return (
		<Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
			<DialogTitle>Update Section</DialogTitle>
			<form onSubmit={handleSubmit}>
				<DialogContent>
					<DialogContentText sx={{ mb: 1 }}>Section</DialogContentText>
					<FormControl required variant="outlined" size="small" fullWidth>
						<OutlinedInput
							inputProps={{
								'aria-label': 'title',
							}}
							autoComplete="off"
							name="title"
							value={data?.title}
							onChange={handleSectionInputChange}
						/>
					</FormControl>
					<Divider sx={{ my: 2 }} />
					<DialogContentText sx={{ mb: 1 }}>Sub Sections</DialogContentText>
					<Stack spacing={2}>
						{data?.items?.map((item) => (
							<FormControl
								required
								variant="outlined"
								size="small"
								fullWidth
								key={item?.subsection_id}
							>
								<OutlinedInput
									inputProps={{
										'aria-label': item?.subsection_id,
									}}
									autoComplete="off"
									name={item?.subsection_id}
									value={item?.title}
									onChange={handleSubSectionInputChange}
								/>
							</FormControl>
						))}
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button type="submit">Save</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default UpdateSection;
