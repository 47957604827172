import { Slide, Snackbar } from '@mui/material';
import React, { forwardRef } from 'react';
import MuiAlert from '@mui/material/Alert';

const TransitionUp = (props) => {
	return <Slide {...props} direction="right" />;
};

const Alert = forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Notification = ({ open, messageInfo, handleClose }) => {
	return (
		<Snackbar
			open={open}
			autoHideDuration={4000}
			onClose={handleClose}
			TransitionComponent={TransitionUp}
		>
			<Alert
				onClose={handleClose}
				severity={messageInfo?.type}
				sx={{ width: '100%' }}
			>
				{messageInfo?.msg}
			</Alert>
		</Snackbar>
	);
};

export default Notification;
