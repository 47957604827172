import {
	doc,
	serverTimestamp,
	updateDoc,
	query,
	orderBy,
	limit,
	collection,
	getDocs,
	addDoc,
	where,
	startAfter,
	deleteDoc,
	collectionGroup,
	getCountFromServer,
} from 'firebase/firestore';
import { db } from '../utils/firebase';

const COLLECTION = 'lead-form';

const createLead = async (data) => {
	try {
		const collectionRef = collection(db, COLLECTION);

		const newLead = {
			full_name: data?.full_name,
			email: data?.email,
			phone_number: data?.phone_number,
			message: data?.message,
			status: false,
			created_at: serverTimestamp(),
		};

		await addDoc(collectionRef, newLead);
		return ['Lead created successfully', null];
	} catch (err) {
		return [null, `Error creating lead: ${err.message}`];
	}
};

const fetchLead = async ({ filters, offset, page_size }) => {
	try {
		let docRef = collection(db, COLLECTION);

		if (filters?.status !== 'all' && offset) {
			docRef = query(
				collectionGroup(db, COLLECTION),
				where('status', '==', filters.status),
				orderBy('created_at', 'desc'),
				startAfter(offset),
				limit(page_size)
			);
		} else if (filters?.status !== 'all') {
			docRef = query(
				collectionGroup(db, COLLECTION),
				where('status', '==', filters.status),
				orderBy('created_at', 'desc'),
				limit(page_size)
			);
		} else if (offset) {
			docRef = query(
				docRef,
				orderBy('created_at', 'desc'),
				startAfter(offset),
				limit(page_size)
			);
		} else
			docRef = query(docRef, orderBy('created_at', 'desc'), limit(page_size));

		const dbVal = await getDocs(docRef);

		const leads = dbVal.docs.map((doc) => ({
			...doc.data(),
			id: doc.id,
		}));

		offset = dbVal.docs[dbVal.docs.length - 1]?.data().created_at;

		return [{ leads, offset }, null];
	} catch (err) {
		return [null, err.message];
	}
};

const updateLeadStatus = async (lead_id, status) => {
	try {
		const docRef = doc(db, COLLECTION, lead_id);

		await updateDoc(docRef, { status });

		return ['Document updated successfully', null];
	} catch (err) {
		return [null, err.message];
	}
};

const deleteLeads = async (ids) => {
	try {
		ids.forEach(async (lead) => {
			await deleteDoc(doc(db, COLLECTION, lead));
		});

		return ['Lead deleted successfully', null];
	} catch (err) {
		return [null, err.message];
	}
};

const getLeadCount = async (status) => {
	try {
		const coll = collection(db, COLLECTION);
		let snapshot;
		if (status === 'all') snapshot = await getCountFromServer(coll);
		else {
			const q = query(coll, where('status', '==', status));
			snapshot = await getCountFromServer(q);
		}

		return [snapshot?.data()?.count ?? 0, null];
	} catch (err) {
		return [null, err.message];
	}
};

const leadControllers = {
	createLead,
	fetchLead,
	updateLeadStatus,
	deleteLeads,
	getLeadCount,
};

export default leadControllers;
