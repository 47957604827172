import {
	doc,
	setDoc,
	collection,
	addDoc,
	getDocs,
	deleteDoc,
} from 'firebase/firestore';
import { db } from '../utils/firebase';

const createAddress = async (address) => {
	try {
		const collectionRef = collection(db, 'addresses'); // Modify based on your collection name
		await addDoc(collectionRef, address);
		return ['Address created successfully', null];
	} catch (err) {
		return [null, `Error while adding address: ${err.message}`];
	}
};

const updateAddress = async (address, id) => {
	try {
		if (!id) {
			throw new Error('Address ID is required for update');
		}
		const docRef = doc(db, 'addresses', id);
		await setDoc(docRef, address);
		return ['Address updated successfully', null];
	} catch (err) {
		return [null, `Error while updating address: ${err.message}`];
	}
};

const deleteAddress = async (addressId) => {
	try {
		const addressRef = doc(db, 'addresses', addressId); // Replace with your collection name
		await deleteDoc(addressRef);
		return ['Address deleted successfully', null];
	} catch (error) {
		return [null, `Error deleting address: ${error.message}`];
	}
};

const fetchAddresses = async () => {
	try {
		const addressesRef = collection(db, 'addresses'); // Replace with your collection name
		const querySnapshot = await getDocs(addressesRef);

		const addresses = querySnapshot.docs.map((doc) => {
			// Create a new object with the document ID and other data
			return {
				id: doc.id,
				...doc.data(),
			};
		});

		// reverse the array to show the oldest address first
		addresses.reverse();

		return addresses;
	} catch (error) {
		console.error('Error fetching addresses:', error);
		return []; // Or an empty array to prevent crashes
	}
};

const addressControllers = {
	createAddress,
	updateAddress,
	deleteAddress,
	fetchAddresses,
};

export default addressControllers;
