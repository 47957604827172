export function generateStyle(child) {
	let style = {};
	if (child.bold) style.fontWeight = 'bold';
	if (child.italic) style.fontStyle = 'italic';
	if (child.underline) style.textDecoration = 'underline';
	return style;
}

export function alignmentHelper(alignment) {
	switch (alignment) {
		case 'left':
			return 'left';
		case 'center':
			return 'center';
		case 'right':
			return 'right';
		case 'justify':
			return 'justify';
		default:
			return '';
	}
}
