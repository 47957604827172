import {
	BriefcaseIcon,
	DocumentCheckIcon,
	CalculatorIcon,
	LightBulbIcon,
	CircleStackIcon,
	BuildingOffice2Icon,
	HomeIcon,
	WrenchScrewdriverIcon,
	MapPinIcon,
	UserGroupIcon,
	DocumentChartBarIcon,
} from '@heroicons/react/24/outline';

const iconList = [
	{
		label: 'Briefcase',
		value: 'BriefcaseIcon',
		icon: (
			<BriefcaseIcon
				style={{
					width: 20,
					height: 20,
				}}
			/>
		),
	},
	{
		label: 'DocumentCheck',
		value: 'DocumentCheckIcon',
		icon: (
			<DocumentCheckIcon
				style={{
					width: 20,
					height: 20,
				}}
			/>
		),
	},
	{
		label: 'Calculator',
		value: 'CalculatorIcon',
		icon: (
			<CalculatorIcon
				style={{
					width: 20,
					height: 20,
				}}
			/>
		),
	},
	{
		label: 'LightBulb',
		value: 'LightBulbIcon',
		icon: (
			<LightBulbIcon
				style={{
					width: 20,
					height: 20,
				}}
			/>
		),
	},
	{
		label: 'CircleStack',
		value: 'CircleStackIcon',
		icon: (
			<CircleStackIcon
				style={{
					width: 20,
					height: 20,
				}}
			/>
		),
	},
	{
		label: 'BuildingOffice',
		value: 'BuildingOffice2Icon',
		icon: (
			<BuildingOffice2Icon
				style={{
					width: 20,
					height: 20,
				}}
			/>
		),
	},
];

const LEAD_STATUS = ['all', 'opened', 'open', 'contacted', 'rejected'];

const TABLE_HEAD = [
	{
		id: 1,
		disablePadding: true,
		label: 'Full Name',
		align: 'left',
	},
	{
		id: 2,
		disablePadding: false,
		label: 'Email',
		align: 'left',
	},
	{
		id: 3,
		disablePadding: false,
		label: 'Phone Number',
		align: 'left',
	},
	{
		id: 4,
		disablePadding: true,
		label: 'Status',
		align: 'center',
	},
];

const NAV_ITEMS = [
	{
		id: 1,
		title: 'Home',
		href: '/',
		icon: (
			<HomeIcon
				style={{
					width: 20,
					height: 20,
				}}
			/>
		),
	},
	{
		id: 2,
		title: 'Services',
		href: '/service',
		icon: (
			<WrenchScrewdriverIcon
				style={{
					width: 20,
					height: 20,
				}}
			/>
		),
	},
	{
		id: 4,
		title: 'About',
		href: '/about',
		icon: (
			<DocumentChartBarIcon
				style={{
					width: 20,
					height: 20,
				}}
			/>
		),
	},
	{
		id: 5,
		title: 'Leads',
		href: '/lead',
		icon: (
			<UserGroupIcon
				style={{
					width: 20,
					height: 20,
				}}
			/>
		),
	},
	{
		id: 6,
		title: 'Saved Addresses',
		href: '/saved-addresses',
		icon: (
			<MapPinIcon
				style={{
					width: 20,
					height: 20,
				}}
			/>
		),
	},
];

export { NAV_ITEMS, iconList, LEAD_STATUS, TABLE_HEAD };
