import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { auth } from '../utils/firebase';

const login = async (email, password) => {
	try {
		const userCredential = await signInWithEmailAndPassword(
			auth,
			email,
			password
		);

		return [userCredential.user, null];
	} catch (err) {
		const [text1, text2] = err?.code?.split('/');
		if (text1 === 'auth') return [null, text2?.replaceAll('-', ' ')];
		return [null, err?.message];
	}
};

const logout = async () => {
	try {
		await signOut(auth);
		return [true, null];
	} catch (err) {
		return [null, err?.message];
	}
};

const userControllers = {
	login,
	logout,
};

export default userControllers;
