import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';

const StatusDropdown = ({ selected, handleSelect, list }) => {
	return (
		<FormControl size="small" sx={{ minWidth: 120 }}>
			<InputLabel id="demo-select-small-label">Status</InputLabel>
			<Select
				value={selected}
				label="Status"
				onChange={(e) => handleSelect(e.target.value)}
			>
				{list.map((item) => (
					<MenuItem
						key={item}
						value={item}
						sx={{
							textTransform: 'capitalize',
						}}
					>
						{item}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default StatusDropdown;
