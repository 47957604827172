const serviceModalHandler = (state, action) => {
	switch (action.type) {
		case 'ADD_SECTION_MODAL':
			return {
				...state,
				addSectionModal: !state.addSectionModal,
			};
		case 'ADD_SUB_SECTION_MODAL':
			return {
				...state,
				addSubSectionModal: !state.addSubSectionModal,
			};
		case 'UPDATE_SECTION_MODAL':
			return {
				...state,
				updateSectionModal: !state.updateSectionModal,
			};
		case 'DELETE_CONFIRMATION_MODAL':
			return {
				...state,
				deleteConfirmationModal: !state.deleteConfirmationModal,
			};
		case 'DELETE_SECTION_MODAL':
			return {
				...state,
				deleteSectionModal: !state.deleteSectionModal,
			};
		default:
			return state;
	}
};

export default serviceModalHandler;
