import React from 'react';
import { alignmentHelper, generateStyle } from '../../utils/helper';
import { Box, Typography } from '@mui/material';

const PageTemplate = ({ data }) => {
	return data.map((item, index) => {
		let alignStyle = alignmentHelper(item?.align);
		switch (item.type) {
			case 'heading-one':
				return (
					<Typography
						key={`h1-${index}`}
						variant="h4"
						sx={{
							textAlign: alignStyle?.length ? alignStyle : 'left',
							my: 1,
						}}
					>
						{item.children.map((child, i) => {
							let style = generateStyle(child);
							return (
								<span key={`h4-${index}-${i}`} style={style}>
									{child.text}
								</span>
							);
						})}
					</Typography>
				);
			case 'heading-two':
				return (
					<Typography
						key={`h2-${index}`}
						variant="h5"
						sx={{
							textAlign: alignStyle?.length ? alignStyle : 'left',
							my: 1,
						}}
					>
						{item.children.map((child, i) => {
							let style = generateStyle(child);
							return (
								<span key={`h5-${index}-${i}`} style={style}>
									{child.text}
								</span>
							);
						})}
					</Typography>
				);
			case 'paragraph':
				return (
					<Typography
						key={`p-${index}`}
						variant="body1"
						sx={{
							textAlign: alignStyle?.length ? alignStyle : 'left',
							my: 1,
						}}
						gutterBottom
					>
						{item.children.map((child, i) => {
							let style = generateStyle(child);
							return (
								<span key={`p-${index}-${i}`} style={style}>
									{child.text}
								</span>
							);
						})}
					</Typography>
				);
			case 'bulleted-list':
				return (
					<ul key={`ul-${index}`} sx={{ my: 1 }}>
						{item.children.map((child, i) => (
							<li key={`ul-${index}-${i}`}>
								{child.children.map((child, i2) => {
									let style = generateStyle(child);
									return (
										<span key={`ul-${index}-${i}-${i2}`} style={style}>
											{child.text}
										</span>
									);
								})}
							</li>
						))}
					</ul>
				);
			case 'numbered-list':
				return (
					<ol key={`ol-${index}`} sx={{ my: 1 }}>
						{item.children.map((child, i) => (
							<li key={`ol-${index}-${i}`}>
								{child.children.map((child, i2) => {
									let style = generateStyle(child);
									return (
										<span key={`ol-${index}-${i}-${i2}`} style={style}>
											{child.text}
										</span>
									);
								})}
							</li>
						))}
					</ol>
				);
			case 'block-quote':
				return (
					<Box
						sx={{
							my: 1,
							borderLeft: 5,
							py: '1px',
							borderColor: '#737373',
							backgroundColor: '#f5f5f5',
							textAlign: alignStyle?.length ? alignStyle : 'left',
							color: '#3f3f3f',
						}}
					>
						<blockquote key={`bq-${index}`}>
							{item.children.map((child, i) => {
								let style = generateStyle(child);
								return (
									<span key={`bq-${index}-${i}`} style={style}>
										{child.text}
									</span>
								);
							})}
						</blockquote>
					</Box>
				);
			default:
				return null;
		}
	});
};

export default PageTemplate;
