import React from 'react';
import { Box, Divider, Grid, Skeleton } from '@mui/material';

const AboutSkeleton = () => {
	return (
		<Box>
			<Skeleton variant="text" height={40} />
			<Skeleton variant="rectangular" width="100%" height={80} />
			<Divider sx={{ my: 3 }} />
			<Skeleton variant="text" height={40} />
			<Skeleton variant="rectangular" width="100%" height={80} />
			<div style={{ marginTop: '1rem' }} />
			<Grid
				container
				spacing={{ xs: 2, md: 3 }}
				columns={{ xs: 4, sm: 8, md: 12 }}
			>
				{[...Array(3)].map((item, index) => (
					<Grid item xs={12} sm={4} md={4} key={index}>
						<Box
							sx={{
								backgroundColor: '#fff8f8',
								padding: '1rem',
								borderRadius: '12px',
							}}
						>
							<Skeleton variant="text" height={40} />

							<Skeleton variant="text" height={70} />
						</Box>
					</Grid>
				))}
			</Grid>
		</Box>
	);
};

export default AboutSkeleton;
