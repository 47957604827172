import React from 'react';
import { ButtonGroup } from '@mui/material';
import MarkButton from './MarkButton';
import BlockButton from './BlockButton';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
// import InsertLinkIcon from '@mui/icons-material/InsertLink';

const ToolBar = ({ editor }) => {
	return (
		<ButtonGroup
			variant="contained"
			aria-label="toolbar button group"
			size="small"
			sx={{ flexWrap: 'wrap' }}
			disableElevation
		>
			<MarkButton editor={editor} format="bold">
				<FormatBoldIcon />
			</MarkButton>
			<MarkButton editor={editor} format="italic">
				<FormatItalicIcon />
			</MarkButton>
			<MarkButton editor={editor} format="underline">
				<FormatUnderlinedIcon />
			</MarkButton>
			{/* <MarkButton editor={editor} format="link">
				<InsertLinkIcon />
			</MarkButton> */}
			<BlockButton editor={editor} format="heading-one">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 -960 960 960"
					className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
				>
					<path d="M200-280v-400h80v160h160v-160h80v400h-80v-160H280v160h-80Zm480 0v-320h-80v-80h160v400h-80Z" />
				</svg>
			</BlockButton>
			<BlockButton editor={editor} format="heading-two">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 -960 960 960"
					className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
				>
					<path d="M120-280v-400h80v160h160v-160h80v400h-80v-160H200v160h-80Zm400 0v-160q0-33 23.5-56.5T600-520h160v-80H520v-80h240q33 0 56.5 23.5T840-600v80q0 33-23.5 56.5T760-440H600v80h240v80H520Z" />
				</svg>
			</BlockButton>
			<BlockButton editor={editor} format="block-quote">
				<FormatQuoteIcon />
			</BlockButton>
			<BlockButton editor={editor} format="numbered-list">
				<FormatListNumberedIcon />
			</BlockButton>
			<BlockButton editor={editor} format="bulleted-list">
				<FormatListBulletedIcon />
			</BlockButton>
			<BlockButton editor={editor} format="left">
				<FormatAlignLeftIcon />
			</BlockButton>
			<BlockButton editor={editor} format="center">
				<FormatAlignCenterIcon />
			</BlockButton>
			<BlockButton editor={editor} format="justify">
				<FormatAlignJustifyIcon />
			</BlockButton>
		</ButtonGroup>
	);
};

export default ToolBar;
