import React from 'react';

import { Editable } from 'slate-react';

const EditorArea = ({ renderElement, renderLeaf }) => {
	return (
		<Editable
			style={{
				padding: 4,
				minHeight: 200,
				border: '1px solid #ccc',
				borderRadius: 4,
			}}
			renderElement={renderElement}
			renderLeaf={renderLeaf}
			placeholder="Enter some rich text…"
			spellCheck
			autoFocus
		/>
	);
};

export default EditorArea;
