import { Box, Skeleton } from '@mui/material';
import React from 'react';

const ServiceSkeleton = () => {
	return (
		<Box>
			<Skeleton variant="text" height={20} sx={{ mb: 3 }} />
			<Skeleton variant="rectangular" width="100%" height={200} />
		</Box>
	);
};

export default ServiceSkeleton;
