import {
	Button,
	Divider,
	IconButton,
	Stack,
	Typography,
	useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import serviceControllers from '../controllers/service.controllers';
import PageTemplate from '../components/Editor/PageTemplate';
import ServiceSkeleton from '../components/Loader/ServiceSkeleton';

const Viewer = () => {
	const navigate = useNavigate();
	const { serviceId } = useParams();
	const matches = useMediaQuery('(max-width:600px)');
	const [page, setPage] = useState(null);
	const [title, setTitle] = useState('');
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const getData = async () => {
			try {
				const res = await serviceControllers.fetchService(serviceId);
				if (res?.body?.length) setPage(res.body);
				else
					setPage([
						{
							type: 'paragraph',
							children: [
								{
									text: '',
								},
							],
						},
					]);
				setTitle(res.title);
				setLoading(false);
			} catch (err) {
				console.log(err);
				setLoading(false);
				return;
			}
		};

		getData();
	}, []);

	if (loading) return <ServiceSkeleton />;

	return (
		<div>
			<Stack direction="row" alignItems="center" spacing={2}>
				{matches ? (
					<IconButton aria-label="back" onClick={() => navigate(-1)}>
						<ArrowBackIcon />
					</IconButton>
				) : (
					<Button onClick={() => navigate(-1)} startIcon={<ArrowBackIcon />}>
						Back
					</Button>
				)}

				<Typography variant="h6" component="div">
					{title}
				</Typography>
			</Stack>
			<Divider sx={{ my: 2 }} />
			<PageTemplate data={page} />
		</div>
	);
};

export default Viewer;
