import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material';
import React from 'react';

const DeleteConfirmation = ({ open, handleClose, data, handleDelete }) => {
	return (
		<Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
			<DialogTitle>Confirm Delete</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Are you sure you want to delete <strong>{data?.title}</strong> page?
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
				<Button onClick={handleDelete}>Delete</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DeleteConfirmation;
