import { Box, Typography } from '@mui/material';
import React from 'react';

const Element = ({ attributes, children, element }) => {
	let style = element.align;

	switch (element.type) {
		case 'block-quote':
			return (
				<Box
					sx={{
						borderLeft: 5,
						borderColor: '#eee',
						textAlign: style ? `${element.align}` : 'left',
						p: {
							marginBottom: 0,
							color: '#9e9e9e',
						},
					}}
				>
					<blockquote>{children}</blockquote>
				</Box>
			);
		case 'heading-one':
			return (
				<Typography
					variant="h4"
					gutterBottom
					sx={{
						textAlign: style ? `${element.align}` : 'left',
					}}
				>
					{children}
				</Typography>
			);
		case 'heading-two':
			return (
				<Typography
					variant="h5"
					gutterBottom
					sx={{
						textAlign: style ? `${element.align}` : 'left',
					}}
				>
					{children}
				</Typography>
			);
		case 'list-item':
			return <li {...attributes}>{children}</li>;
		case 'bulleted-list':
			return <ul {...attributes}>{children}</ul>;
		case 'numbered-list':
			return <ol {...attributes}>{children}</ol>;
		default:
			return (
				<Typography
					variant="body1"
					gutterBottom
					sx={{ textAlign: style ? `${element.align}` : 'left' }}
				>
					{children}
				</Typography>
			);
	}
};

export default Element;
