import React from 'react';
import {
	Navigate,
	RouterProvider,
	createBrowserRouter,
} from 'react-router-dom';

// Views
import Layout from './views/Layout';
import Home from './views/Home';
import About from './views/About';
import Login from './views/Login';
import Service from './views/Service';
import Lead from './views/Lead';
import Editor from './views/Editor';
import Viewer from './views/Viewer';
import Addresses from './views/Addresses';

// Context
import { useFirebaseAuth } from './actions/FirebaseAuthContext';

const App = () => {
	const { currentUser } = useFirebaseAuth();

	const router = createBrowserRouter([
		{
			path: '/',
			element: currentUser ? <Layout /> : <Navigate to="/login" />,
			children: [
				{
					path: '/',
					element: <Home />,
				},
				{
					path: '/about',
					element: <About />,
				},
				{
					path: '/service',
					element: <Service />,
				},
				{
					path: '/service/edit/:serviceId',
					element: <Editor />,
				},
				{
					path: '/service/view/:serviceId',
					element: <Viewer />,
				},
				{
					path: '/lead',
					element: <Lead />,
				},
				{
					path: '/saved-addresses',
					element: <Addresses />,
				},
			],
		},
		{
			path: '/login',
			element: !currentUser ? <Login /> : <Navigate to="/" />,
		},
	]);

	return <RouterProvider router={router} />;
};

export default App;
