import {
	Box,
	Button,
	Divider,
	Grid,
	MenuItem,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { iconList } from '../utils/constants';
import homeControllers from '../controllers/home.controllers';
import HomeSkeleton from '../components/Loader/HomeSkeleton';
import Notification from '../components/Common/Notification';

const Home = () => {
	const [loading, setLoading] = useState(true);
	const [formData, setFormData] = useState(null);
	const [notification, setNotification] = useState(null);
	const [closeNotif, setCloseNotif] = useState(false);

	const handleInputChange = (section, key, value) => {
		setFormData((prevData) => ({
			...prevData,
			[section]: {
				...prevData[section],
				[key]: value,
			},
		}));
	};

	const handleSection2ContentChange = (index, key, value) => {
		setFormData((prevData) => ({
			...prevData,
			section2: {
				...prevData.section2,
				card_with_icon: prevData.section2.card_with_icon.map((item, i) =>
					i === index ? { ...item, [key]: value } : item
				),
			},
		}));
	};

	const getData = async () => {
		try {
			const data = await homeControllers.fetchHome('home-page');
			if (!data)
				setFormData({
					section1: {
						title: '',
						content: '',
					},
					section2: {
						title: '',
						content: '',
						card_with_icon: Array(6).fill({
							content: '',
							icon: 'BriefcaseIcon',
							link: '',
							title: '',
						}),
					},
				});
			else setFormData(data);
			setLoading(false);
		} catch (err) {
			setNotification({ msg: err, type: 'error' });
			setCloseNotif(true);
			setLoading(false);
		}
	};

	useEffect(() => {
		getData();
	}, []);

	const handleSubmit = async (e) => {
		e.preventDefault();
		const [data, errForUpdate] = await homeControllers.setHome(
			formData,
			'home-page'
		);
		if (errForUpdate) {
			setNotification({ msg: errForUpdate, type: 'error' });
			setCloseNotif(true);
			return;
		}

		setNotification({ msg: data, type: 'success' });
		setCloseNotif(true);
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setCloseNotif(false);
	};

	if (loading) return <HomeSkeleton />;

	return (
		<Box>
			<Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
				Section 1
			</Typography>

			<TextField
				fullWidth
				label="Title"
				sx={{ mb: 3 }}
				size="small"
				value={formData?.section1?.title}
				onChange={(e) => handleInputChange('section1', 'title', e.target.value)}
			/>

			<TextField
				label="Content"
				fullWidth
				multiline
				rows={3}
				value={formData?.section1?.content}
				onChange={(e) =>
					handleInputChange('section1', 'content', e.target.value)
				}
			/>

			<Divider sx={{ my: 3 }} />
			<Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
				Section 2
			</Typography>
			<TextField
				fullWidth
				label="Title"
				sx={{ mb: 3 }}
				size="small"
				value={formData?.section2?.title}
				onChange={(e) =>
					handleSection2ContentChange('section2', 'title', e.target.value)
				}
			/>
			<TextField
				label="Content"
				fullWidth
				multiline
				rows={2}
				sx={{ mb: 3 }}
				value={formData?.section2?.content}
				onChange={(e) =>
					handleSection2ContentChange('section2', 'content', e.target.value)
				}
			/>

			<Typography variant="body1" gutterBottom sx={{ mb: 2 }}>
				Action Cards
			</Typography>

			<Grid
				container
				spacing={{ xs: 2, md: 3 }}
				columns={{ xs: 4, sm: 8, md: 12 }}
			>
				{formData.section2?.card_with_icon?.map((item, index) => (
					<Grid item xs={12} sm={4} md={4} key={index}>
						<Box
							sx={{
								backgroundColor: '#fff8f8',
								padding: '1rem',
								borderRadius: '12px',
							}}
						>
							<TextField
								fullWidth
								label="Route"
								variant="outlined"
								size="small"
								sx={{ mb: 2 }}
								placeholder="/about"
								value={item?.link}
								onChange={(e) =>
									handleSection2ContentChange(index, 'link', e.target.value)
								}
							/>
							<TextField
								select
								label="Icon"
								size="small"
								fullWidth
								sx={{ mb: 2 }}
								value={item?.icon}
								onChange={(event) => {
									handleSection2ContentChange(
										index,
										'icon',
										event.target.value
									);
								}}
							>
								{iconList.map((option) => (
									<MenuItem key={option.value} value={option.value}>
										<Stack direction="row" spacing={2} alignItems="center">
											{option.icon}
											<Typography variant="body">{option.label}</Typography>
										</Stack>
									</MenuItem>
								))}
							</TextField>
							<TextField
								fullWidth
								label="Title"
								variant="outlined"
								size="small"
								sx={{ mb: 2 }}
								value={item?.title}
								onChange={(e) =>
									handleSection2ContentChange(index, 'title', e.target.value)
								}
							/>
							<TextField
								fullWidth
								label="Content"
								variant="outlined"
								size="small"
								multiline
								rows={3}
								value={item?.content}
								onChange={(e) =>
									handleSection2ContentChange(index, 'content', e.target.value)
								}
							/>
						</Box>
					</Grid>
				))}
			</Grid>
			<Button
				sx={{ mt: 3 }}
				onClick={handleSubmit}
				fullWidth
				variant="contained"
				disableElevation
				color="success"
			>
				Save
			</Button>
			{notification && (
				<Notification
					open={closeNotif}
					messageInfo={notification}
					handleClose={handleClose}
				/>
			)}
		</Box>
	);
};

export default Home;
