import {
	Box,
	Button,
	Divider,
	Grid,
	TextField,
	Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import aboutControllers from '../controllers/about.controllers';
import AboutSkeleton from '../components/Loader/AboutSkeleton';
import Notification from '../components/Common/Notification';

const About = () => {
	const [loading, setLoading] = useState(true);
	const [formData, setFormData] = useState({
		section1: {
			title: '',
			content: '',
		},
		section2: {
			title: '',
			content: '',
		},
		section3: [],
	});
	const [notification, setNotification] = useState(null);
	const [closeNotif, setCloseNotif] = useState(false);

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setCloseNotif(false);
	};

	const getData = async () => {
		try {
			const data = await aboutControllers.fetchAbout('about-page');
			if (!data) {
				setLoading(false);
				return;
			}
			setFormData(data);
			setLoading(false);
		} catch (err) {
			setNotification({ msg: err, type: 'error' });
			setCloseNotif(true);
			setLoading(false);
			return;
		}
	};

	useEffect(() => {
		getData();
	}, []);

	const handleSubmit = async (e) => {
		e.preventDefault();
		const id = 'about-page';
		const [data, errForUpdate] = await aboutControllers.setAbout(formData, id);
		if (errForUpdate) {
			setNotification({ msg: errForUpdate, type: 'error' });
			setCloseNotif(true);
			return;
		}

		setNotification({ msg: data, type: 'success' });
		setCloseNotif(true);
	};

	const handleInputChange = (section, key, value) => {
		setFormData((prevData) => ({
			...prevData,
			[section]: {
				...prevData[section],
				[key]: value,
			},
		}));
	};

	const handleSection3ItemChange = (index, key, value) => {
		setFormData((prevData) => ({
			...prevData,
			section3: prevData.section3.map((item, i) =>
				i === index ? { ...item, [key]: value } : item
			),
		}));
	};

	if (loading) return <AboutSkeleton />;

	return (
		<Box>
			<Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
				Section 1
			</Typography>
			<TextField
				fullWidth
				label="Title"
				sx={{ mb: 3 }}
				size="small"
				value={formData.section1.title}
				onChange={(e) => handleInputChange('section1', 'title', e.target.value)}
			/>
			<TextField
				label="Content"
				fullWidth
				multiline
				rows={2}
				value={formData.section1.content}
				onChange={(e) =>
					handleInputChange('section1', 'content', e.target.value)
				}
			/>
			<Divider sx={{ my: 3 }} />
			<Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
				Section 2
			</Typography>
			<TextField
				fullWidth
				label="Title"
				sx={{ mb: 3 }}
				size="small"
				value={formData.section2.title}
				onChange={(e) => handleInputChange('section2', 'title', e.target.value)}
			/>
			<TextField
				label="Content"
				fullWidth
				multiline
				rows={6}
				value={formData.section2.content}
				onChange={(e) =>
					handleInputChange('section2', 'content', e.target.value)
				}
			/>
			<Divider sx={{ my: 3 }} />
			<Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
				Section 3
			</Typography>
			<Grid
				container
				spacing={{ xs: 2, md: 3 }}
				columns={{ xs: 4, sm: 8, md: 12 }}
			>
				{formData.section3.map((item, index) => (
					<Grid item xs={12} sm={4} md={4} key={index}>
						<Box
							sx={{
								backgroundColor: '#fff8f8',
								padding: '1rem',
								borderRadius: '12px',
							}}
						>
							<TextField
								fullWidth
								label="Title"
								variant="outlined"
								size="small"
								sx={{ mb: 2 }}
								value={item.title}
								onChange={(e) =>
									handleSection3ItemChange(index, 'title', e.target.value)
								}
							/>
							<TextField
								fullWidth
								label="Content"
								variant="outlined"
								size="small"
								multiline
								rows={4}
								value={item.content}
								onChange={(e) =>
									handleSection3ItemChange(index, 'content', e.target.value)
								}
							/>
						</Box>
					</Grid>
				))}
			</Grid>
			<Button
				sx={{ mt: 3 }}
				onClick={handleSubmit}
				fullWidth
				variant="contained"
				disableElevation
				color="success"
			>
				Save
			</Button>
			{notification && (
				<Notification
					open={closeNotif}
					messageInfo={notification}
					handleClose={handleClose}
				/>
			)}
		</Box>
	);
};

export default About;
