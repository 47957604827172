import React, { useEffect, useState } from 'react';
// MUI Components
import {
	Button,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemText,
	Paper,
	Popover,
	Skeleton,
	Stack,
	Typography,
} from '@mui/material';

// MUI icons
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// Components
import AddressModel from '../components/Models/AddressModel';
import Notification from '../components/Common/Notification';
import DeleteModel from '../components/Models/DeleteModel';

// Controllers
import addressControllers from '../controllers/address.controllers';

const Addresses = () => {
	const [loading, setLoading] = useState(true);
	const [addresses, setAddresses] = useState(null);
	const [notification, setNotification] = useState(null);
	const [closeNotif, setCloseNotif] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [openCreateAddress, setOpenCreateAddress] = useState(false);
	const [openDeleteAddress, setOpenDeleteAddress] = useState(false);
	const [openEditAddress, setOpenEditAddress] = useState(false);
	const [addressIndex, setAddressIndex] = useState(null);
	const [addressToUpdate, setAddressToUpdate] = useState(null);

	const handleMenuClick = (event, address) => {
		setAnchorEl(event.currentTarget);
		setAddressIndex(address?.id);
		setAddressToUpdate(address);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
		setAddressIndex(null);
		setAddressToUpdate(null);
	};

	const getData = async () => {
		try {
			const data = await addressControllers.fetchAddresses();
			setAddresses(data);
			setLoading(false);
		} catch (err) {
			setNotification({ msg: err, type: 'error' });
			setCloseNotif(true);
			setLoading(false);
		}
	};

	useEffect(() => {
		getData();
	}, []);

	const createAddress = async (data) => {
		setAnchorEl(null);
		const [result, errForCreate] = await addressControllers.createAddress(data);
		if (errForCreate) {
			setNotification({ msg: errForCreate, type: 'error' });
			setCloseNotif(true);
			return;
		}
		getData();
		setNotification({ msg: result, type: 'success' });
		setCloseNotif(true);
	};

	const updateAddress = async (data) => {
		setAnchorEl(null);

		const [result, errForUpdate] = await addressControllers.updateAddress(
			data,
			addressIndex
		);
		if (errForUpdate) {
			setNotification({ msg: errForUpdate, type: 'error' });
			setCloseNotif(true);
			return;
		}
		getData();
		setNotification({ msg: result, type: 'success' });
		setCloseNotif(true);
	};

	const deleteAddress = async () => {
		setAnchorEl(null);
		const [result, errForDelete] = await addressControllers.deleteAddress(
			addressIndex
		);
		if (errForDelete) {
			setNotification({ msg: errForDelete, type: 'error' });
			setCloseNotif(true);
			return;
		}
		getData();
		setNotification({ msg: result, type: 'success' });
		setOpenDeleteAddress(false);
		setCloseNotif(true);
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setCloseNotif(false);
	};

	if (loading) return <Skeleton variant="text" height={80} />;

	return (
		<Paper sx={{ p: '1rem' }}>
			<Typography
				variant="h5"
				sx={{
					textAlign: {
						xs: 'center', // Center on extra small screens
						sm: 'left', // Align left on small screens and above
					},
				}}
			>
				Manage Addresses
			</Typography>
			<Button
				variant="outlined"
				startIcon={<AddIcon />}
				fullWidth
				sx={{ mt: 2 }}
				onClick={() => setOpenCreateAddress(true)}
			>
				Add a new address
			</Button>
			<List dense sx={{ bgcolor: 'background.paper' }}>
				{addresses.map((address) => (
					<ListItem
						key={address.id}
						sx={{ my: 1 }}
						secondaryAction={
							<IconButton
								edge="end"
								aria-label="options"
								onClick={(e) => {
									handleMenuClick(e, address);
								}}
							>
								<MoreVertIcon />
							</IconButton>
						}
					>
						<ListItemText
							primary={address.address}
							secondary={
								<React.Fragment>
									<Typography variant="body2" color="text.primary">
										{address.mobile && address.phone
											? `${address.mobile}, ${address.phone}`
											: address.mobile || address.phone}
									</Typography>
									<Typography variant="body2" color="text.primary">
										{address.primary_email && address.other_email
											? `${address.primary_email}, ${address.other_email}`
											: address.primary_email || address.other_email}
									</Typography>

									<Divider sx={{ mt: 1 }} />
								</React.Fragment>
							}
						/>
						{Boolean(anchorEl) && (
							<Popover
								open={Boolean(anchorEl)}
								anchorEl={anchorEl}
								onClose={handleMenuClose}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}
							>
								<Stack sx={{ m: 1 }}>
									<Button
										onClick={() => {
											setOpenEditAddress(true);
										}}
									>
										Edit
									</Button>
									<Divider />
									<Button
										onClick={() => {
											setOpenDeleteAddress(true);
										}}
									>
										Remove
									</Button>
								</Stack>
							</Popover>
						)}
					</ListItem>
				))}
			</List>

			{openCreateAddress && (
				<AddressModel
					open={openCreateAddress}
					handleClose={() => {
						setOpenCreateAddress(false);
						setAnchorEl(null);
					}}
					title="Create Address"
					handleSubmit={createAddress}
				/>
			)}

			{openEditAddress && (
				<AddressModel
					open={openEditAddress}
					handleClose={() => {
						setOpenEditAddress(false);
						setAnchorEl(null);
					}}
					title="Edit Address"
					handleSubmit={updateAddress}
					data={addressToUpdate}
					index={addressIndex}
				/>
			)}
			{openDeleteAddress && (
				<DeleteModel
					open={openDeleteAddress}
					handleClose={() => {
						setOpenDeleteAddress(false);
						setAnchorEl(null);
					}}
					text="Are you sure you want to delete this address?"
					handleDelete={deleteAddress}
					index={addressIndex}
				/>
			)}
			{notification && (
				<Notification
					open={closeNotif}
					messageInfo={notification}
					handleClose={handleClose}
				/>
			)}
		</Paper>
	);
};

export default Addresses;
