import { Box, Divider, Skeleton } from '@mui/material';
import React from 'react';

const EditorSkeleton = () => {
	return (
		<Box>
			<Skeleton variant="text" height={20} sx={{ mb: 4 }} />
			<Skeleton variant="rectangular" width="100%" height={40} />
			<Divider sx={{ my: 2 }} />
			<Skeleton variant="rectangular" width="100%" height={100} />
		</Box>
	);
};

export default EditorSkeleton;
