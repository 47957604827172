import React, { useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Stack,
	TextField,
} from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { v4 as uuidv4 } from 'uuid';
import { grey } from '@mui/material/colors';

const AddSubSection = ({ open, handleClose, data, handleCreate }) => {
	const [subSection, setSubSection] = useState([
		{ title: '', subsection_id: uuidv4(), is_published: false },
	]);

	const handleChange = (i, event) => {
		const values = [...subSection];
		values[i][event.target.name] = event.target.value;
		setSubSection(values);
	};

	const handleAdd = () => {
		setSubSection([
			...subSection,
			{ title: '', subsection_id: uuidv4(), is_published: false },
		]);
	};

	const handleRemove = (i) => {
		const values = [...subSection];
		values.splice(i, 1);
		setSubSection(values);
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		handleCreate([...data.items, ...subSection]);
		handleClose();
		setSubSection([
			{ title: '', subsection_id: uuidv4(), is_published: false },
		]);
	};

	return (
		<Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
			<DialogTitle>
				Add <strong>{data?.title}</strong> sub section
			</DialogTitle>
			<DialogContent>
				<List sx={{ mb: 1 }} dense>
					{data?.items?.map((item) => (
						<ListItem disablePadding key={item?.subsection_id}>
							<ListItemIcon>
								<VerifiedIcon
									color={item?.is_published ? 'success' : grey[100]}
								/>
							</ListItemIcon>
							<ListItemText primary={item?.title} />
						</ListItem>
					))}
				</List>

				{subSection.map((item, idx) => (
					<Stack
						direction="row"
						key={idx}
						alignItems="center"
						spacing={2}
						sx={{ my: 1 }}
					>
						<TextField
							variant="outlined"
							fullWidth
							size="small"
							name="title"
							value={item?.title}
							onChange={(e) => handleChange(idx, e)}
						/>
						<IconButton size="small" onClick={() => handleRemove(idx)}>
							<HighlightOffIcon />
						</IconButton>
					</Stack>
				))}
			</DialogContent>
			<DialogActions>
				<Button onClick={() => handleAdd()}>Add More</Button>
				<Button onClick={handleClose}>Cancel</Button>
				<Button onClick={handleSubmit}>Save</Button>
			</DialogActions>
		</Dialog>
	);
};

export default AddSubSection;
