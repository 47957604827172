import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
	Box,
	Checkbox,
	IconButton,
	LinearProgress,
	Paper,
	Skeleton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Toolbar,
	Tooltip,
	Typography,
} from '@mui/material';
import StatusHandler from '../components/Table/StatusHandler';
import MessageModal from '../components/Table/MessageModal';
import StatusDropdown from '../components/Table/StatusDropdown';
import { TABLE_HEAD, LEAD_STATUS } from '../utils/constants';
import leadControllers from '../controllers/lead.controllers';
import Notification from '../components/Common/Notification';

function EnhancedTableToolbar(props) {
	const { numSelected, handleDelete, filterStatus, handleFilterStatus } = props;

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) =>
						alpha(
							theme.palette.primary.main,
							theme.palette.action.activatedOpacity
						),
				}),
			}}
		>
			{numSelected > 0 ? (
				<Typography
					sx={{ flex: '1 1 100%' }}
					color="inherit"
					variant="subtitle1"
					component="div"
				>
					{numSelected} selected
				</Typography>
			) : (
				<Typography
					sx={{ flex: '1 1 100%' }}
					variant="h6"
					id="tableTitle"
					component="div"
				>
					Leads List
				</Typography>
			)}

			{numSelected > 0 ? (
				<Tooltip title="Delete">
					<IconButton onClick={handleDelete}>
						<DeleteIcon />
					</IconButton>
				</Tooltip>
			) : (
				<Stack
					direction="row"
					spacing={2}
					alignItems="center"
					justifyContent="space-between"
					sx={{
						backgroundColor: '#f5f5f5',
						borderRadius: '5px',
						padding: '0.5rem 1rem',
					}}
				>
					<FilterListIcon />
					<StatusDropdown
						selected={filterStatus}
						handleSelect={handleFilterStatus}
						list={LEAD_STATUS}
					/>
				</Stack>
			)}
		</Toolbar>
	);
}

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
};

const Lead = () => {
	const [selected, setSelected] = useState([]);
	const [message, setMessage] = useState(null);
	const [open, setOpen] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [filterStatus, setFilterStatus] = useState('all');
	const [rows, setRows] = useState([]);
	const [total, setTotal] = useState(0);
	const [offset, setOffset] = useState(null);
	const [loading, setLoading] = useState(true);
	const [loader, setLoader] = useState(false);
	const [notification, setNotification] = useState(null);
	const [closeNotif, setCloseNotif] = useState(false);

	const handleFilterStatus = (status) => {
		setFilterStatus(status);
	};

	const handleOpenMessage = (data) => {
		setMessage(data);
		setOpen(true);
	};

	const handleCloseMessage = () => {
		setMessage(null);
		setOpen(false);
	};

	const handleClick = (event, id) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}
		setSelected(newSelected);
	};

	const handleDelete = async () => {
		const [data, errForDeleteLead] = await leadControllers.deleteLeads(
			selected
		);
		if (errForDeleteLead) {
			setNotification({ msg: errForDeleteLead, type: 'error' });
			setCloseNotif(true);
			return;
		}

		setNotification({ msg: data, type: 'success' });
		setCloseNotif(true);
		setSelected([]);
		getLeads(filterStatus, rowsPerPage);
		setTotal(total - selected.length);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const isSelected = (id) => selected.indexOf(id) !== -1;

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	const visibleRows = useMemo(
		() => rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
		[rows, page, rowsPerPage]
	);

	const handleChangePage = async (event, newPage) => {
		setLoader(true);
		if (
			rows?.length < newPage * rowsPerPage + rowsPerPage &&
			rows[rows?.length - 1]?.created_at?.nanoseconds === offset?.nanoseconds
		) {
			const [data, errForFetchLead] = await leadControllers.fetchLead({
				filters: {
					status: filterStatus,
				},
				offset: offset,
				page_size: rowsPerPage,
			});
			if (errForFetchLead) {
				setNotification({ msg: errForFetchLead, type: 'error' });
				setCloseNotif(true);
				return;
			}
			setRows([...rows, ...data?.leads]);
			setOffset(data?.offset);
		}

		setPage(newPage);
		setLoader(false);
	};

	const getLeads = async (filterStatus, rowsPerPage) => {
		setLoader(true);
		const [data, err] = await leadControllers.fetchLead({
			filters: {
				status: filterStatus,
			},
			page_size: rowsPerPage,
		});
		if (err) {
			setNotification({ msg: err, type: 'error' });
			setCloseNotif(true);
			return;
		}

		setRows(data?.leads);
		setOffset(data?.offset);
		setPage(0);
		setLoading(false);
		setLoader(false);
	};

	const getTotal = async (status) => {
		const [count, errForCount] = await leadControllers.getLeadCount(status);
		if (errForCount) {
			setNotification({ msg: errForCount, type: 'error' });
			setCloseNotif(true);
			return;
		}

		setTotal(count);
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setCloseNotif(false);
	};

	useEffect(() => {
		getTotal(filterStatus);
	}, [filterStatus]);

	useEffect(() => {
		getLeads(filterStatus, rowsPerPage);
	}, [filterStatus, rowsPerPage]);

	if (loading) return <Skeleton variant="rectangular" height={400} />;

	return (
		<Box sx={{ width: '100%' }}>
			<Paper sx={{ width: '100%', mb: 2 }}>
				<EnhancedTableToolbar
					numSelected={selected.length}
					handleDelete={handleDelete}
					filterStatus={filterStatus}
					handleFilterStatus={handleFilterStatus}
				/>
				{loader && <LinearProgress />}
				<TableContainer>
					<Table sx={{ minWidth: 750 }} aria-labelledby="Lead list">
						<TableHead>
							<TableRow>
								<TableCell padding="checkbox" />
								{TABLE_HEAD.map((headCell) => (
									<TableCell
										key={headCell.id}
										padding={headCell.disablePadding ? 'none' : 'normal'}
										align={headCell.align}
									>
										{headCell.label}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{visibleRows.map((row, index) => {
								const isItemSelected = isSelected(row.id);
								const labelId = `enhanced-table-checkbox-${index}`;
								return (
									<TableRow
										hover
										onClick={(event) => {
											if (event.target.type !== 'checkbox') {
												handleOpenMessage(row);
											}
										}}
										tabIndex={-1}
										key={row.id}
										sx={{ cursor: 'pointer' }}
									>
										<TableCell padding="checkbox">
											<Checkbox
												color="primary"
												checked={isItemSelected}
												onChange={(event) => handleClick(event, row.id)}
												inputProps={{
													'aria-labelledby': labelId,
												}}
											/>
										</TableCell>
										<TableCell
											component="th"
											id={labelId}
											scope="row"
											padding="none"
											sx={{ width: { md: 280 } }}
										>
											{row.full_name.length > 24
												? row.full_name.substring(0, 24) + '...'
												: row.full_name}
										</TableCell>
										<TableCell>
											{row.email.length > 24
												? row.email.substring(0, 24) + '...'
												: row.email}
										</TableCell>
										<TableCell>{row.phone_number}</TableCell>
										<TableCell align="center">
											<StatusHandler status={row.status} />
										</TableCell>
									</TableRow>
								);
							})}
							{emptyRows > 0 && (
								<TableRow style={{ height: 53 * emptyRows }}>
									<TableCell colSpan={5} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[10, 25, 50]}
					component="div"
					count={total}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
			{message && (
				<MessageModal
					open={open}
					handleClose={handleCloseMessage}
					data={message}
				/>
			)}

			{notification && (
				<Notification
					open={closeNotif}
					messageInfo={notification}
					handleClose={handleClose}
				/>
			)}
		</Box>
	);
};

export default Lead;
