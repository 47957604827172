import React, { useEffect, useReducer, useState } from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import {
	Box,
	Button,
	ButtonGroup,
	Divider,
	Stack,
	Typography,
	useMediaQuery,
} from '@mui/material';
import ActionButton from '../components/service/ActionButton';
import serviceModalHandler from '../reducers/serviceModalHandler';
import AddSection from '../components/service/AddSection';
import UpdateSection from '../components/service/UpdateSection';
import AddSubSection from '../components/service/AddSubSection';
import DeleteConfirmation from '../components/service/DeleteConfirmation';
import serviceControllers from '../controllers/service.controllers';
import { useNavigate } from 'react-router-dom';
import ServiceSkeleton from '../components/Loader/ServiceSkeleton';
import Notification from '../components/Common/Notification';

const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	'&:not(:last-child)': {
		borderBottom: 0,
	},
	'&:before': {
		display: 'none',
	},
}));

const AccordionSummary = styled((props) => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '1rem' }} />}
		{...props}
	/>
))(({ theme }) => ({
	backgroundColor: 'rgba(0, 0, 0, .03)',
	flexDirection: 'row-reverse',

	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1),
		alignItems: 'center',
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const initialState = {
	addSectionModal: false,
	addSubSectionModal: false,
	updateSectionModal: false,
	deleteConfirmationModal: false,
	deleteSectionModal: false,
};

export default function CustomizedAccordions() {
	const navigate = useNavigate();
	const matches = useMediaQuery('(max-width:600px)');
	const [state, dispatch] = useReducer(serviceModalHandler, initialState);
	const [expanded, setExpanded] = useState('panel1');
	const [rowData, setRowData] = useState(null);
	const [routeData, setRouteData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [notification, setNotification] = useState(null);
	const [closeNotif, setCloseNotif] = useState(false);

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setCloseNotif(false);
	};

	const handleChange = (panel) => (event, newExpanded) => {
		if (event.target.closest('.MuiAccordionSummary-expandIconWrapper'))
			setExpanded(newExpanded ? panel : false);
	};

	const createSection = async ({ title, section_id }) => {
		const updatedData = [...routeData, { title, section_id, items: [] }];

		const [_, errForSetRoute] = await serviceControllers.setServiceRoutes(
			{ data: updatedData },
			'routes'
		);
		if (errForSetRoute) {
			setNotification({ msg: errForSetRoute, type: 'error' });
			setCloseNotif(true);
			return;
		}

		setRouteData(updatedData);
	};

	const handleCreateSubSection = (section) => {
		dispatch({ type: 'ADD_SUB_SECTION_MODAL' });
		setRowData(section);
	};

	const createSubSection = async (items) => {
		const updatedData = routeData.map((section) => {
			if (section?.section_id === rowData?.section_id) {
				return { ...section, items };
			}
			return section;
		});

		// Create the page for the services
		items.forEach(async (item) => {
			const [__, errForServiceSection] =
				await serviceControllers.setServiceSection({
					data: { title: item?.title, body: [] },
					subsection_id: item.subsection_id,
				});
			if (errForServiceSection) {
				setNotification({ msg: errForServiceSection, type: 'error' });
				setCloseNotif(true);
				return;
			}
		});

		// Update the route
		const [_, errForSetRoute] = await serviceControllers.setServiceRoutes(
			{ data: updatedData },
			'routes'
		);
		if (errForSetRoute) {
			setNotification({ msg: errForSetRoute, type: 'error' });
			setCloseNotif(true);
			return;
		}

		setRouteData(updatedData);
		setRowData(null);
	};

	const handleUpdateSection = (section) => {
		dispatch({ type: 'UPDATE_SECTION_MODAL' });
		setRowData(section);
	};

	const updateSection = async () => {
		let currData = [];
		const updatedData = routeData.map((section) => {
			if (section?.section_id === rowData?.section_id) {
				currData = section?.items;
				return {
					...section,
					title: rowData?.title?.trim(),
					items: rowData?.items,
				};
			}
			return section;
		});

		const [_, errForSetRoute] = await serviceControllers.setServiceRoutes(
			{ data: updatedData },
			'routes'
		);
		if (errForSetRoute) {
			setNotification({ msg: errForSetRoute, type: 'error' });
			setCloseNotif(true);
			return;
		}

		for (let i = 0; i < currData?.length; i++) {
			if (currData[i]?.title !== rowData?.items[i]?.title) {
				await serviceControllers.updateTitle(
					currData[i].subsection_id,
					rowData.items[i].title?.trim()
				);
			}
		}

		setRouteData(updatedData);
		setRowData(null);
	};

	const handleDeleteSection = async ({ section_id, title }) => {
		dispatch({ type: 'DELETE_SECTION_MODAL' });
		setRowData({ section_id, title });
	};

	const handleDeleteSectionConfirm = async () => {
		const subsectionIds = [];
		const updatedData = routeData.filter((section) => {
			if (section?.section_id === rowData?.section_id) {
				section.items.forEach((item) => subsectionIds.push(item.subsection_id));
				return false;
			}
			return true;
		});

		if (subsectionIds?.length) {
			// Delete the row from the service section
			const [__, errForServiceSection] =
				await serviceControllers.deleteServiceSection(subsectionIds);
			if (errForServiceSection) {
				setNotification({ msg: errForServiceSection, type: 'error' });
				setCloseNotif(true);
				return;
			}
		}

		const [_, errForSetRoute] = await serviceControllers.setServiceRoutes(
			{ data: updatedData },
			'routes'
		);
		if (errForSetRoute) {
			setNotification({ msg: errForSetRoute, type: 'error' });
			setCloseNotif(true);
			return;
		}

		setRouteData(updatedData);
		dispatch({ type: 'DELETE_SECTION_MODAL' });
		setRowData(null);
	};

	const handleUpdatePublish = async (row) => {
		const updatedData = routeData.map((section) => {
			if (section?.section_id === row?.section_id) {
				const updatedItems = section.items.map((item) => {
					if (item?.subsection_id === row?.subsection_id) {
						return { ...item, is_published: row?.is_published };
					}
					return item;
				});
				return { ...section, items: updatedItems };
			}
			return section;
		});

		const [_, errForSetRoute] = await serviceControllers.setServiceRoutes(
			{ data: updatedData },
			'routes'
		);
		if (errForSetRoute) {
			setNotification({ msg: errForSetRoute, type: 'error' });
			setCloseNotif(true);
			return;
		}
		setRouteData(updatedData);
	};

	const handleDeleteRow = (row) => {
		dispatch({ type: 'DELETE_CONFIRMATION_MODAL' });
		setRowData(row);
	};

	const handleDelete = async () => {
		const updatedData = routeData.map((section) => {
			if (section?.section_id === rowData?.section_id) {
				const updatedItems = section.items.filter(
					(item) => item?.subsection_id !== rowData?.subsection_id
				);
				return { ...section, items: updatedItems };
			}
			return section;
		});

		const [_, errForSetRoute] = await serviceControllers.setServiceRoutes(
			{ data: updatedData },
			'routes'
		);
		if (errForSetRoute) {
			setNotification({ msg: errForSetRoute, type: 'error' });
			setCloseNotif(true);
			return;
		}

		// Delete the row from the service section
		const [__, errForServiceSection] =
			await serviceControllers.deleteServiceSection([rowData.subsection_id]);
		if (errForServiceSection) {
			setNotification({ msg: errForServiceSection, type: 'error' });
			setCloseNotif(true);
			return;
		}

		setRouteData(updatedData);
		dispatch({ type: 'DELETE_CONFIRMATION_MODAL' });
		setRowData(null);
	};

	useEffect(() => {
		const getData = async () => {
			try {
				const res = await serviceControllers.fetchServiceRoutes('routes');

				setRouteData(res.data);
				setLoading(false);
			} catch (err) {
				setNotification({ msg: err, type: 'error' });
				setCloseNotif(true);
				setLoading(false);
				return;
			}
		};

		getData();
	}, []);

	if (loading) return <ServiceSkeleton />;

	return (
		<div>
			<Button
				variant="contained"
				sx={{ mb: 3, left: '100%', transform: 'translateX(-100%)' }}
				fullWidth={matches}
				disabled={routeData?.length > 5}
				onClick={() => dispatch({ type: 'ADD_SECTION_MODAL' })}
			>
				Add section
			</Button>
			{routeData?.map((parent) => (
				<Accordion
					expanded={expanded === parent?.section_id}
					onChange={handleChange(parent?.section_id)}
					key={parent?.section_id}
				>
					<AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
						<Typography flexGrow={1}>{parent?.title}</Typography>
						<ButtonGroup variant="outlined" aria-label="outlined button group">
							<ActionButton
								title="Add"
								color="success"
								handleClick={() => handleCreateSubSection(parent)}
							/>
							<ActionButton
								title="Update"
								color="warning"
								handleClick={() => handleUpdateSection(parent)}
							/>
							<ActionButton
								title="Delete"
								color="error"
								handleClick={() =>
									handleDeleteSection({
										section_id: parent?.section_id,
										title: parent?.title,
									})
								}
							/>
						</ButtonGroup>
					</AccordionSummary>
					<AccordionDetails>
						{parent?.items?.map((row, index) => (
							<Box key={row.subsection_id}>
								<Stack
									direction={{ xs: 'column', sm: 'row' }}
									sx={{
										px: 2,
										py: 1,
									}}
									justifyContent="space-between"
									alignItems="center"
								>
									<Typography flexGrow={1}>{row.title}</Typography>
									<ButtonGroup
										variant="outlined"
										aria-label="outlined button group"
									>
										<ActionButton
											title="Edit"
											handleClick={() =>
												navigate(`/service/edit/${row?.subsection_id}`)
											}
										/>
										<ActionButton
											title="Preview"
											color="success"
											handleClick={() =>
												navigate(`/service/view/${row?.subsection_id}`)
											}
										/>
										<ActionButton
											title={row?.is_published ? 'Unpublished' : 'Publish'}
											handleClick={() =>
												handleUpdatePublish({
													...row,
													is_published: !row.is_published,
													section_id: parent?.section_id,
												})
											}
										/>
										<ActionButton
											title="Delete"
											color="error"
											handleClick={() =>
												handleDeleteRow({
													...row,
													section_id: parent?.section_id,
												})
											}
										/>
									</ButtonGroup>
								</Stack>
								{index !== parent.items.length - 1 && (
									<Divider sx={{ my: 1 }} />
								)}
							</Box>
						))}
					</AccordionDetails>
				</Accordion>
			))}
			{state.addSectionModal && (
				<AddSection
					open={state.addSectionModal}
					handleClose={() => dispatch({ type: 'ADD_SECTION_MODAL' })}
					handleCreate={createSection}
				/>
			)}
			{state.updateSectionModal && (
				<UpdateSection
					open={state.updateSectionModal}
					handleClose={() => dispatch({ type: 'UPDATE_SECTION_MODAL' })}
					data={rowData}
					setData={setRowData}
					handleUpdate={updateSection}
				/>
			)}
			{state.addSubSectionModal && (
				<AddSubSection
					open={state.addSubSectionModal}
					handleClose={() => dispatch({ type: 'ADD_SUB_SECTION_MODAL' })}
					data={rowData}
					handleCreate={createSubSection}
				/>
			)}
			{state.deleteConfirmationModal && (
				<DeleteConfirmation
					open={state.deleteConfirmationModal}
					handleClose={() => dispatch({ type: 'DELETE_CONFIRMATION_MODAL' })}
					data={rowData}
					handleDelete={handleDelete}
				/>
			)}
			{state.deleteSectionModal && (
				<DeleteConfirmation
					open={state.deleteSectionModal}
					handleClose={() => dispatch({ type: 'DELETE_SECTION_MODAL' })}
					data={rowData}
					handleDelete={handleDeleteSectionConfirm}
				/>
			)}
			{notification && (
				<Notification
					open={closeNotif}
					messageInfo={notification}
					handleClose={handleClose}
				/>
			)}
		</div>
	);
}
