import {
	doc,
	setDoc,
	collection,
	getDoc,
	writeBatch,
	updateDoc,
} from 'firebase/firestore';
import { db } from '../utils/firebase';

const setServiceRoutes = async (data, id) => {
	try {
		const docRef = collection(db, 'services');
		const docCol = doc(docRef, id);
		await setDoc(docCol, data);
		return ['Added service', null];
	} catch (err) {
		return [null, `Error adding service: ${err.message}`];
	}
};

const fetchServiceRoutes = async (page_id) => {
	try {
		const docRef = doc(db, 'services', page_id);
		const docSnap = await getDoc(docRef);
		if (docSnap.exists()) return docSnap.data();
		return { data: [] };
	} catch (err) {
		return `Error getting data: ${err.message}`;
	}
};

const setServiceSection = async ({ data, subsection_id }) => {
	try {
		const docRef = collection(db, 'services');
		const docCol = doc(docRef, subsection_id);
		await setDoc(docCol, data);
		return ['Updated service subsection', null];
	} catch (err) {
		return [null, `Error adding service: ${err.message}`];
	}
};

const fetchService = async (subsection_id) => {
	try {
		const docRef = doc(db, 'services', subsection_id);
		const docSnap = await getDoc(docRef);
		if (docSnap.exists()) return docSnap.data();
		return 'Error Loading Page. Please refresh.';
	} catch (err) {
		return `Error getting data: ${err.message}`;
	}
};

const deleteServiceSection = async (section_ids) => {
	try {
		const batch = writeBatch(db);

		for (const docId of section_ids) {
			batch.delete(doc(db, 'services', docId));
		}

		await batch.commit();
		return ['Deleted Service', null];
	} catch (err) {
		return [null, `Error deleting service: ${err.message}`];
	}
};

const updateTitle = async (subsection_id, new_title) => {
	try {
		const docRef = doc(db, 'services', subsection_id);
		await updateDoc(docRef, {
			title: new_title,
		});

		return ['Updated Title', null];
	} catch (err) {
		return [null, `Error updating title: ${err.message}`];
	}
};

const serviceControllers = {
	setServiceRoutes,
	fetchServiceRoutes,
	setServiceSection,
	fetchService,
	deleteServiceSection,
	updateTitle,
};

export default serviceControllers;
