import { Box, Toolbar } from '@mui/material';
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import userControllers from '../controllers/user.controllers';
import AppNavBar from '../components/Common/AppNavBar';
import MiniDrawer from '../components/Common/MiniDrawer';

const drawerWidth = 240;

const Layout = (props) => {
	const [mobileOpen, setMobileOpen] = useState(false);
	const [isClosing, setIsClosing] = useState(false);

	const handleDrawerClose = () => {
		setIsClosing(true);
		setMobileOpen(false);
	};

	const handleDrawerTransitionEnd = () => {
		setIsClosing(false);
	};

	const handleDrawerToggle = () => {
		if (!isClosing) {
			setMobileOpen(!mobileOpen);
		}
	};

	const handleLogout = async () => {
		const [_, errForLogout] = await userControllers.logout();
		if (errForLogout) console.log(errForLogout);
	};

	return (
		<Box sx={{ display: 'flex' }}>
			<AppNavBar
				handleDrawerToggle={handleDrawerToggle}
				drawerWidth={drawerWidth}
			/>

			<MiniDrawer
				mobileOpen={mobileOpen}
				handleDrawerClose={handleDrawerClose}
				handleDrawerTransitionEnd={handleDrawerTransitionEnd}
				handleLogout={handleLogout}
				drawerWidth={drawerWidth}
			/>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					p: 3,
					width: { sm: `calc(100% - ${drawerWidth}px)` },
				}}
			>
				<Toolbar />
				{/* data goes here */}
				<Outlet />
			</Box>
		</Box>
	);
};

export default Layout;
