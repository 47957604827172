import { useTheme } from '@emotion/react';
import { Button, IconButton, Tooltip, useMediaQuery } from '@mui/material';
import React from 'react';
import AddCircle from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import PublishIcon from '@mui/icons-material/Publish';
import PreviewIcon from '@mui/icons-material/Preview';
import EditIcon from '@mui/icons-material/Edit';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

const ActionButton = ({ handleClick, color, title }) => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up('sm'));

	let icon;
	switch (title?.toLowerCase()) {
		case 'add':
			icon = <AddCircle />;
			break;
		case 'delete':
			icon = <DeleteIcon />;
			break;
		case 'unpublished':
			icon = <UnpublishedIcon />;
			break;
		case 'publish':
			icon = <PublishIcon />;
			break;
		case 'preview':
			icon = <PreviewIcon />;
			break;
		case 'edit':
			icon = <DriveFileRenameOutlineIcon />;
			break;
		case 'update':
			icon = <EditIcon />;
			break;
		default:
			icon = null;
	}

	if (matches) {
		return (
			<Button onClick={handleClick} color={color}>
				{title}
			</Button>
		);
	} else {
		return (
			<Tooltip title={title}>
				<IconButton onClick={handleClick} color={color}>
					{icon}
				</IconButton>
			</Tooltip>
		);
	}
};

export default ActionButton;
