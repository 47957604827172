import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import serviceControllers from '../controllers/service.controllers';
import {
	Button,
	IconButton,
	Paper,
	Stack,
	Typography,
	useMediaQuery,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SlateEditor from '../components/Editor/SlateEditor';
import EditorSkeleton from '../components/Loader/EditorSkeleton';
import Notification from '../components/Common/Notification';

const Editor = () => {
	const navigate = useNavigate();
	const { serviceId } = useParams();
	const matches = useMediaQuery('(max-width:600px)');
	const [page, setPage] = useState(null);
	const [title, setTitle] = useState('');
	const [loading, setLoading] = useState(true);
	const [open, setOpen] = useState(false);
	const [messageInfo, setMessageInfo] = useState(null);

	const handleSubmit = async (e) => {
		e.preventDefault();
		const updatedData = { body: page, title: title };
		const [res, errForUpdate] = await serviceControllers.setServiceRoutes(
			updatedData,
			serviceId
		);
		if (errForUpdate) {
			setMessageInfo({ msh: errForUpdate, type: 'error' });
			setOpen(true);
			return;
		}

		setMessageInfo({ msg: res, type: 'success' });
		setOpen(true);
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};

	const getData = async () => {
		try {
			const res = await serviceControllers.fetchService(serviceId);
			if (res?.body?.length) setPage(res.body);
			else
				setPage([
					{
						type: 'paragraph',
						children: [
							{
								text: '',
							},
						],
					},
				]);
			setTitle(res.title);
			setLoading(false);
		} catch (err) {
			setMessageInfo({ msg: err, type: 'error' });
			setOpen(true);
			setLoading(false);
			return;
		}
	};

	useEffect(() => {
		getData();
	}, []);

	if (loading) return <EditorSkeleton />;

	return (
		<div>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={2}
			>
				{matches ? (
					<IconButton aria-label="back" onClick={() => navigate(-1)}>
						<ArrowBackIcon />
					</IconButton>
				) : (
					<Button onClick={() => navigate(-1)} startIcon={<ArrowBackIcon />}>
						Back
					</Button>
				)}

				<Typography variant="h5" component="div">
					{title}
				</Typography>
				<Button
					variant="contained"
					color="success"
					disableElevation
					onClick={handleSubmit}
				>
					Save
				</Button>
			</Stack>

			<Paper sx={{ p: 2, mt: 2 }} variant="outlined">
				<SlateEditor data={page} setData={setPage} />
			</Paper>
			<Notification
				open={open}
				messageInfo={messageInfo}
				handleClose={handleClose}
			/>
		</div>
	);
};

export default Editor;
