import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../utils/firebase';

const setAbout = async (data, id) => {
	try {
		const aboutRef = doc(db, 'about', id);
		await setDoc(aboutRef, data);
		return ['About page updated successfully', null];
	} catch (err) {
		return [null, err.message];
	}
};

const fetchAbout = async (page_id) => {
	try {
		const docRef = doc(db, 'about', page_id);
		const docSnap = await getDoc(docRef);
		if (docSnap.exists()) return docSnap.data();
		return null;
	} catch (err) {
		return `Error fetching About page: ${err.message}`;
	}
};

const aboutControllers = {
	setAbout,
	fetchAbout,
};

export default aboutControllers;
