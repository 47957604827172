import React from 'react';
import customEditor from '../../utils/customEditor';
import { Button } from '@mui/material';

const BlockButton = ({ editor, children, format }) => {
	const isActive = customEditor.isBlockActive(editor, format);
	return (
		<Button
			color={isActive ? 'info' : 'primary'}
			onMouseDown={(e) => {
				e.preventDefault();
				customEditor.toggleBlock(editor, format);
			}}
		>
			{children}
		</Button>
	);
};

export default BlockButton;
