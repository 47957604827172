import { Chip } from '@mui/material';
import React from 'react';
import DoDisturbAltRoundedIcon from '@mui/icons-material/DoDisturbAltRounded';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';

const StatusHandler = ({ status }) => {
	switch (status) {
		case 'opened':
			return (
				<Chip
					icon={<ErrorRoundedIcon />}
					label="Opened"
					variant="outlined"
					color="primary"
					size="small"
				/>
			);

		case 'rejected':
			return (
				<Chip
					icon={<DoDisturbAltRoundedIcon />}
					label="Rejected"
					variant="outlined"
					color="error"
					size="small"
				/>
			);

		case 'contacted':
			return (
				<Chip
					icon={<CheckCircleOutlineIcon />}
					label="Contacted"
					variant="outlined"
					color="success"
					size="small"
				/>
			);

		default:
			return (
				<Chip
					icon={<CircleRoundedIcon />}
					label="Open"
					variant="outlined"
					size="small"
				/>
			);
	}
};

export default StatusHandler;
