import * as React from 'react';
// MUI Components
import {
	Divider,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	ListItemIcon,
	Toolbar,
	Box,
	Drawer,
	Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

// MUI Icons
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

// Utils
import { NAV_ITEMS } from '../../utils/constants';

const MiniDrawer = ({
	mobileOpen,
	handleDrawerClose,
	handleDrawerTransitionEnd,
	handleLogout,
	drawerWidth = 240,
}) => {
	const navigate = useNavigate();
	const drawer = (
		<div>
			<Toolbar />
			<Divider />
			<List>
				{NAV_ITEMS.map((item) => (
					<ListItem
						disablePadding
						sx={{
							...(window.location.pathname === item.href
								? {
										color: '#29b6f6',
										'& .MuiListItemIcon-root': {
											color: '#29b6f6', // Customize icon color for active state
										},
								  }
								: {}),
						}}
					>
						<ListItemButton onClick={() => navigate(item.href)}>
							<ListItemIcon>{item.icon}</ListItemIcon>
							<ListItemText primary={item.title} />
						</ListItemButton>
					</ListItem>
				))}
			</List>

			<Box
				sx={{
					position: 'absolute',
					bottom: '0',
					left: '0',
					width: '100%',
					p: '1rem',
				}}
			>
				<Button
					onClick={handleLogout}
					variant="outlined"
					fullWidth
					color="error"
					startIcon={<LogoutOutlinedIcon />}
				>
					Logout
				</Button>
			</Box>
		</div>
	);

	return (
		<Box
			component="nav"
			sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
			aria-label="mailbox folders"
		>
			{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
			<Drawer
				variant="temporary"
				open={mobileOpen}
				onTransitionEnd={handleDrawerTransitionEnd}
				onClose={handleDrawerClose}
				ModalProps={{
					keepMounted: true, // Better open performance on mobile.
				}}
				sx={{
					display: { xs: 'block', sm: 'none' },
					'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
				}}
			>
				{drawer}
			</Drawer>
			<Drawer
				variant="permanent"
				sx={{
					display: { xs: 'none', sm: 'block' },
					'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
				}}
				open
			>
				{drawer}
			</Drawer>
		</Box>
	);
};

export default MiniDrawer;
