import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
	palette: {
		info: {
			light: '#e3f2fd',
			main: '#90caf9',
			contrastText: '#fff',
		},
		secondary: {
			light: '#f3e5f5',
			main: '#ce93d8',
			contrastText: '#fff',
		},
		error: {
			light: '#e57373',
			main: '#f44336',
			contrastText: '#fff',
		},
		warning: {
			light: '#ffb74d',
			main: '#ffa726',
			contrastText: '#fff',
		},
		primary: {
			light: '#4fc3f7',
			main: '#29b6f6',
			contrastText: '#fff',
		},
		success: {
			light: '#81c784',
			main: '#66bb6a',
			contrastText: '#fff',
		},
	},
});

export default theme;
