import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	TextField,
} from '@mui/material';
import React, { useState } from 'react';

const AddressModel = ({ open, handleClose, title, data, handleSubmit }) => {
	const initialData = data || {
		address: '',
		mobile: '',
		phone: '',
		primary_email: '',
		other_email: '',
	};
	const [address, setAddress] = useState(initialData);
	const handleInputChange = (event) => {
		setAddress({
			...address,
			[event.target.name]: event.target.value || '', // Use empty string for empty values
		});
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperProps={{
				component: 'form',
				onSubmit: (event) => {
					event.preventDefault();
					handleSubmit(address);
					handleClose();
				},
			}}
		>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<TextField
							required
							name="address"
							label="Address"
							value={address.address}
							fullWidth
							autoComplete="shipping address-line1"
							variant="standard"
							onChange={handleInputChange}
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<TextField
							name="mobile"
							label="Mobile Number"
							value={address.mobile}
							fullWidth
							variant="standard"
							onChange={handleInputChange}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							name="phone"
							label="Phone Number"
							value={address.phone}
							fullWidth
							variant="standard"
							onChange={handleInputChange}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							name="primary_email"
							label="Primary Email"
							value={address.primary_email}
							fullWidth
							variant="standard"
							onChange={handleInputChange}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							name="other_email"
							label="Other Email"
							value={address.other_email}
							fullWidth
							variant="standard"
							onChange={handleInputChange}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
				<Button type="submit" color="success">
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default AddressModel;
