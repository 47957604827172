import React, { useCallback, useState } from 'react';
import { createEditor } from 'slate';
import { Slate, withReact } from 'slate-react';
import Element from './Element';
import Leaf from './Leaf';
import EditorArea from './EditorArea';
import ToolBar from './ToolBar';
import { Divider } from '@mui/material';

const SlateEditor = (props) => {
	const { data, setData } = props;
	const renderElement = useCallback((props) => <Element {...props} />, []);
	const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
	const [editor] = useState(() => withReact(createEditor()));
	return (
		<Slate
			editor={editor}
			initialValue={data}
			onChange={(value) => {
				const isAstChange = editor.operations.some(
					(op) => 'set_selection' !== op.type
				);
				if (isAstChange) {
					setData(value);
				}
			}}
		>
			<ToolBar editor={editor} />
			<Divider sx={{ my: 1 }} />
			<EditorArea renderElement={renderElement} renderLeaf={renderLeaf} />
		</Slate>
	);
};

export default SlateEditor;
