import { doc, setDoc, collection, getDoc } from 'firebase/firestore';
import { db } from '../utils/firebase';

const setHome = async (data, id) => {
	try {
		const homeRef = collection(db, 'home');
		const homeCol = doc(homeRef, id);
		await setDoc(homeCol, data);
		return ['Home page updated successfully', null];
	} catch (err) {
		return [null, `Error updating home: ${err.message}`];
	}
};

const fetchHome = async (page_id) => {
	try {
		const docRef = doc(db, 'home', page_id);
		const docSnap = await getDoc(docRef);
		if (docSnap.exists()) return docSnap.data();
		return null;
	} catch (err) {
		return `Error getting data: ${err.message}`;
	}
};

const homeControllers = {
	setHome,
	fetchHome,
};

export default homeControllers;
