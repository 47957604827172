import React from 'react';
// MUI Components
import { AppBar, IconButton, Toolbar } from '@mui/material';
// MUI Icons
import MenuIcon from '@mui/icons-material/Menu';

const AppNavBar = ({ handleDrawerToggle, drawerWidth = 240 }) => {
	return (
		<AppBar
			position="fixed"
			sx={{
				width: { sm: `calc(100% - ${drawerWidth}px)` },
				ml: { sm: `${drawerWidth}px` },
			}}
			color="inherit"
			elevation={1}
		>
			<Toolbar>
				<IconButton
					color="inherit"
					aria-label="open drawer"
					edge="start"
					onClick={handleDrawerToggle}
					sx={{ mr: 2, display: { sm: 'none' } }}
				>
					<MenuIcon />
				</IconButton>
				<div>
					<img
						src="/logo.png"
						alt="logo"
						style={{
							display: { sm: 'none' },
							width: '160px',
							height: '36px',
						}}
					/>
				</div>
			</Toolbar>
		</AppBar>
	);
};

export default AppNavBar;
